import "./App.css";
import { useState } from "react";

function App() {
  // const [note, setNote] = useState({
  //   noteTitle: "test title",
  //   noteBody: "test of note body"
  // });
  const [note, setNote] = useState({
    id: Date.now(),
    body: "",
    title: "",
    editing: false,
    editBody: "",
  });
  const [allNotes, setallNotes] = useState([]);

  // const [currentNoteId, setCurrentNoteId] = useState(
  //   (allNotes[0] && allNotes[0].id) || ""
  // );
  //i think this says if notes[0] exists and its id exists then its that, or else just leave it empty string

  //this handles the text input
  const handleBodyChange = (event) => {
    setNote((n) => ({
      ...n,
      //id: allNotes.length + 1,
      body: event.target.value,
      editBody: event.target.value,
    }));
  };
  //this handles tthe submit button and will submit a new note in the currentnotes array
  const handleClick = () => {
    //setNote(n => ({...n, id:allNotes.length}));
    setNote((n) => ({ ...n, id: Date.now(), title: "", body: "" }));
    setallNotes((allN) => [...allN, note]);
  };

  console.log({ note, allNotes });

  function handleTitleChange(e) {
    setNote((n) => ({ ...n, title: e.target.value }));
  }
  function displayEditArea(noteid) {
    // click handler
    // set this note to be editing mode:
    //iterate over all my notes, find the note with the id i want to edit,
    //once tthe note is found i need to set the note and change editing to be true
    console.log("assissnee", noteid);
    setallNotes((oldNotes) => {
      let newNote;
      const newNoteArray = [];
      for (
        let i = 0;
        i < oldNotes.length;
        i++ //iteratte over the notes
      ) {
        if (noteid === oldNotes[i].id) {
          newNote = { ...oldNotes[i], editing: true };
          newNoteArray.push(newNote);
          // setNote((n) => ({ ...n, editing: true }));
          //if u find the matching note then set it to be true
        } else {
          newNoteArray.push(oldNotes[i]);
        }
      }
      return newNoteArray;
    });
    // set the note with this id to editing=>true
  }
  function deleteNote(noteid) {
    console.log(allNotes.filter((n) => n.id !== noteid));
    setallNotes(
      (oldNotes) => oldNotes.filter((n) => n.id !== noteid) //the note id should not match the id coming in
    );
  }
  function confirmEdits(noteid, editBody) {
    setallNotes((oldNotes) => {
      let newNote;
      const newNoteArray = [];
      for (
        let i = 0;
        i < oldNotes.length;
        i++ //iteratte over the notes
      ) {
        if (noteid === oldNotes[i].id) {
          newNote = {
            ...oldNotes[i],
            body: editBody,
            editing: false,
          };
          newNoteArray.push(newNote);
          // setNote((n) => ({ ...n, editing: true }));
          //if u find the matching note then set it to be true
        } else {
          newNoteArray.push(oldNotes[i]);
        }
      }
      return newNoteArray;
    });
  }

  return (
    <div className="App">
      <h1>Notes</h1>
      <p>Start writing some notes!</p>
      <div className="formHolder" id="myForm">
        <div className="mb-3">
          <div className="notemakingarea">
            <p htmlFor="note" className="form-label">
              Note:
            </p>
            <input
              placeholder="Note Title"
              value={note.title}
              onChange={handleTitleChange}
            ></input>
            <textarea
              type="text"
              className="form-control note-body-input"
              id="note"
              name="note"
              placeholder="Enter a note here..."
              onChange={handleBodyChange}
              value={note.body}
            />
          </div>
          <br></br>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={handleClick}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="mb-3" id="noteholder">
        {allNotes.length === 0 ? (
          <p>No notes to display.</p>
        ) : (
          allNotes.map((n) => (
            <div className="eachNote">
              <p className="notetitle">{n.title}</p>

              {n.editing ? (
                <>
                  <textarea
                    className="note-body-input edit"
                    value={n.editBody || n.body}
                    onChange={(e) =>
                      setallNotes((prevNotes) =>
                        prevNotes.map((nEditing) => {
                          console.log(
                            "testing issues",
                            e.target.val,
                            n.body,
                            "word",
                            n.editBody
                          );
                          if (nEditing.id === n.id) {
                            return {
                              ...nEditing,
                              editBody: e.target.value,
                            };
                          } else {
                            return nEditing; //What does this line do
                          }
                        })
                      )
                    }
                  />
                  <button
                    type="button"
                    className="btn btn-outline-info"
                    onClick={() => confirmEdits(n.id, n.editBody)}
                  >
                    Confirm
                  </button>
                </>
              ) : (
                <div>
                  <p className="note-body">{n.body}</p>
                  <div className="notebuttons">
                    <button
                      type="button"
                      className="btn btn-outline-info"
                      onClick={() => displayEditArea(n.id)}
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() => deleteNote(n.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default App;
